exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-areas-js": () => import("./../../../src/pages/areas.js" /* webpackChunkName: "component---src-pages-areas-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-culture-js": () => import("./../../../src/pages/culture.js" /* webpackChunkName: "component---src-pages-culture-js" */),
  "component---src-pages-gdansk-eternum-js": () => import("./../../../src/pages/gdansk-eternum.js" /* webpackChunkName: "component---src-pages-gdansk-eternum-js" */),
  "component---src-pages-gothenburg-citygate-js": () => import("./../../../src/pages/gothenburg-citygate.js" /* webpackChunkName: "component---src-pages-gothenburg-citygate-js" */),
  "component---src-pages-grow-your-skills-js": () => import("./../../../src/pages/grow-your-skills.js" /* webpackChunkName: "component---src-pages-grow-your-skills-js" */),
  "component---src-pages-how-we-care-for-you-js": () => import("./../../../src/pages/how-we-care-for-you.js" /* webpackChunkName: "component---src-pages-how-we-care-for-you-js" */),
  "component---src-pages-how-we-hire-js": () => import("./../../../src/pages/how-we-hire.js" /* webpackChunkName: "component---src-pages-how-we-hire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internships-js": () => import("./../../../src/pages/internships.js" /* webpackChunkName: "component---src-pages-internships-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-poznan-baltyk-js": () => import("./../../../src/pages/poznan-baltyk.js" /* webpackChunkName: "component---src-pages-poznan-baltyk-js" */),
  "component---src-pages-poznan-concordia-js": () => import("./../../../src/pages/poznan-concordia.js" /* webpackChunkName: "component---src-pages-poznan-concordia-js" */),
  "component---src-pages-stockholm-kista-js": () => import("./../../../src/pages/stockholm-kista.js" /* webpackChunkName: "component---src-pages-stockholm-kista-js" */),
  "component---src-pages-university-outreach-js": () => import("./../../../src/pages/university-outreach.js" /* webpackChunkName: "component---src-pages-university-outreach-js" */),
  "component---src-pages-wroclaw-cp-1-js": () => import("./../../../src/pages/wroclaw-cp1.js" /* webpackChunkName: "component---src-pages-wroclaw-cp-1-js" */),
  "component---src-templates-job-offer-template-js": () => import("./../../../src/templates/job-offer-template.js" /* webpackChunkName: "component---src-templates-job-offer-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-thesis-template-js": () => import("./../../../src/templates/thesis-template.js" /* webpackChunkName: "component---src-templates-thesis-template-js" */)
}

